<template>
  <div class="page">
    <Viewheader :isHome="false" ref="navheader" />
    <!-- banner图 -->
    <BannerHead :title="bigTitle_1" :desc="smallTitle_1" :bannerImg="bannerImg">
      <div class="header-custom" slot="content">
        <img class="banner1" src="@/assets/img/title/banner-1.png" />
        <img class="banner3" src="@/assets/img/title/banner-2.png" />
        <img class="banner4" src="@/assets/img/title/banner-3.png" />
        <img class="banner2" src="@/assets/img/title/banner-4.png" />
      </div>
    </BannerHead>

    <!-- 核心功能 -->
    <div class="core-function">
      <CommonTitle title="核心功能" />
      <ColumuTwo
        class="core-function_main"
        :list="spanList"
        :img="coreImg"
      ></ColumuTwo>
    </div>

    <!-- 能力展示 -->
    <div class="similar-main-wrap">
      <div class="similar-main">
        <CommonTitle title="能力展示" :desc="ability.desc" />
        <div class="title_class">
          <div class="title_clas1">
            <!-- 请输入商品文本 -->
            <div class="title_clas2">
              <p>请输入商品文本：<a @click="randomClicked">随机示例</a></p>
              <div class="titleClass1">
                <el-form
                  :model="formUrl"
                  :class="{ frosted_glassing: isActive }"
                >
                  <el-form-item
                    label="商品标题："
                    label-width="84px"
                    class="formText_analyze"
                  >
                    <el-input
                      v-model="formUrl.oragnalTitle"
                      placeholder="请输入完整英文商品标题；知悉场景提示；"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="首图URL："
                    label-width="84px"
                    class="formText_analyze"
                  >
                    <el-input v-model="formUrl.imgUrl" class="input-suffix">
                      <template #suffix>
                        <el-upload
                          class="upload-demo"
                          :action="action1"
                          :on-success="handlePreview"
                          :on-remove="handleRemove"
                          :limit="limit"
                          style="margin-left: 20px"
                        >
                          <el-button size="small" type="primary"
                            >点击上传</el-button
                          >
                        </el-upload></template
                      ></el-input
                    >
                  </el-form-item>
                  <el-form-item
                    label="类目名："
                    label-width="84px"
                    class="formText_analyze"
                  >
                    <el-input v-model="formUrl.cateName"></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      type="primary"
                      @click="submitForm('formUrl')"
                      class="chuBtn"
                      >开始处理</el-button
                    >
                  </el-form-item>
                </el-form>

                <div class="msg-tiped-title" style="display: none" id="msgTip">
                  文本解析中...
                </div>
                <div class="msg-tipsed" style="display: none" id="msgTips">
                  很抱歉，无法识别，请刷新后重新输入
                </div>
              </div>
            </div>

            <div class="title_clas2">
              <p>处理结果：</p>
              <div class="txt_class_sev">
                <p>
                  <span class="txt_class_sev_sp">新标题：</span>
                  <span class="txt_class_sev_sp1">{{
                    formUrlListValue.newTitle
                  }}</span>
                </p>
                <p>
                  <span class="txt_class_sev_sp">选定商品词：</span>
                  <span class="txt_class_sev_sp1">{{
                    formUrlListValue.productWord
                  }}</span>
                </p>
                <p>
                  <span class="txt_class_sev_sp">更换词：</span>
                  <span class="txt_class_sev_sp1">{{
                    formUrlListValue.newWord
                  }}</span>
                </p>
                <div class="img_old">
                  <span class="txt_class_sev_sp">原图：</span>
                  <img
                    :src="formUrlListValue.imgUrl"
                    v-if="formUrlListValue.imgUrl.length > 0"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->

    <!-- 差异优势 -->
    <div class="different-advantage-wrap">
      <div class="different-advantage">
        <CommonTitle title="差异优势" theme="light" />
        <ColumuTwo
          class="different-advantage_main different-advantage_main_top"
          theme="light"
          :list="diffList[0]"
        >
          <div class="diff-top" slot="img">
            <p>训练集数据</p>
            <img :src="diffImg" alt="" />
            <div class="diff-num">7,000W+</div>
            <!-- <div class="diff-num">
              <div v-for="(itemNum, indexNum) in num" :key="indexNum">
                <div
                  :style="{
                    height: numArr[indexNum].length - 1 + '00%',
                    animationDuration: indexNum / 10 + 0.5 + 's',
                  }"
                >
                  <div
                    v-for="(item, index) in numArr[indexNum]"
                    :key="index"
                    :style="{ height: index == 0 ? 0 : 60 + 'px' }"
                  >
                    {{ item }}
                  </div>
                </div>
                <span v-if="comma.indexOf(indexNum + 1) > -1">,</span>
              </div>
            </div> -->
          </div>
        </ColumuTwo>
        <ColumuTwo
          class="different-advantage_main different-advantage_main_bottom"
          :list="diffList[1]"
          :reverse="true"
          theme="light"
        >
          <div slot="img" class="diff-imgs">
            <img class="diff-bar" :src="diffImg2" alt="" />
            <div class="diff-list">
              <img src="@/assets/img/title/diff-3.png" alt="" />
              <img src="@/assets/img/title/diff-4.png" alt="" />
              <img src="@/assets/img/title/diff-5.png" alt="" />
            </div>
            <img
              class="diff-person"
              src="@/assets/img/title/diff-2.png"
              alt=""
            />
          </div>
        </ColumuTwo>
      </div>
    </div>

    <!-- 应用场景 -->
    <div class="application-scene-wrap">
      <div class="application-scene">
        <CommonTitle title="应用场景" />
        <div class="application-bag">
          <img src="@/assets/img/title/app-bag.png" alt="" />
        </div>
        <ColumuTwo class="application-scene_main" :list="appliList">
          <div slot="img" class="app-imgs">
            <img
              class="app-phone"
              src="@/assets/img/title/app-phone.png"
              alt=""
            />
            <img class="app-mac" src="@/assets/img/title/app-mac.png" alt="" />
          </div>
        </ColumuTwo>
      </div>
    </div>

    <!-- 尾部 -->
    <Footering />
  </div>
</template>

<script>
// banner
import BannerHead from './components/Banner.vue';
import CommonTitle from './components/CommonTitle.vue';
import ColumuTwo from './components/ColumuTwo.vue';

import banner1 from '@/assets/img/title/banner-bg.png';

import googleLogo from '@/assets/img/imgSimilar/google.png';
import en from '@/assets/img/titleAnalysis/ability-en.png';

// 公用组件
import '../../assets/css/public.css';
import '../../assets/css/imgPublic.css';
import '../../assets/css/commonModule.css';

import { postSubmitForm, apiUri } from '@/api/txt.js';
// 头部
import Viewheader from '../../components/viewheader/viewheader.vue';
// foote组件
import Footering from '@/components/foot/footer.vue';

export default {
  components: {
    BannerHead,
    CommonTitle,
    ColumuTwo,
    Viewheader,
    Footering,
  },
  data() {
    return {
      bannerImg: banner1,
      coreImg: require('@/assets/img/title/core-1.png'),
      bigTitle_1: '商品标题<span style="color: #6C38E0;">引流效果放大</span>',
      smallTitle_1:
        '优化商品标题，提升标题在google产品系下引流的精准性、引流效率',
      spanList: [
        {
          title: '标题<span style="color: #6C38E0;">通顺度</span>提升',
          desc: '对原有标题通顺度进⾏优化，解决词堆砌、重要信息不凸显、不符合阅读习惯等问题。',
        },
        {
          title: '标题<span style="color: #6C38E0;">⻓尾词</span>布局',
          desc: '替换商品标题中的商品词，使其更符合英语语境表达、表达更精确避免泛词竞争，且在google上有更⼤的搜索量。',
        },
        {
          title: '标题与⾸图<span style="color: #6C38E0;">相关性</span>提升',
          desc: '优化标题使其与⾸图的相关性更⾼，提升点击率及流量精准性。同时，提升google算法内容评分。',
        },
      ],
      diffImg: require('@/assets/img/title/diff-num.png'),
      diffImg2: require('@/assets/img/title/diff-1.png'),
      diffList: [
        [
          {
            title: '跨境场景数据训练',
            desc: '提升图⽂相关性的多模态模型，利⽤近7000w跨境电商数据训练。对跨境数据集能够更好的识别效果。',
          },
        ],
        [
          {
            title: '标题⻓尾词布局',
            desc: '已在⾃身业务shopping、seo流量场景落地应⽤，并在单item引流uv、item均gmv等指标上 提升10%+。',
          },
        ],
      ],
      appliList: [
        {
          title: 'Google shopping引流',
          desc: '在此场景 批量优化商品标题素材，可很⼤程度上提升标题与⾸图的相关性、同时增加⻓尾精准商品词的布局。优化google搜索query与结果集的相关性，同时提升了⾕歌算法内容质量评分。使商品内容的排名靠前，点击进展流量更精准。',
        },
        {
          title: 'SEO商品<span style="color: #6C38E0;">内容质量</span>优化',
          desc: '可应用在站内商品标题优化、SEO页面TDK中。谷歌爬虫机制将商品内容的相关性、标题的可读性等作为页面质量的重要依据，优化标题可提升seo的页面收录、排名、item引流效率等指标。',
        },
      ],
      // 能力展示
      ability: {
        desc: `模型⽬前仅⽀持<img src='${en}' /><span style='color:#6C38E0'>英⽂</span>标题，其他语种需进⾏翻译后处理。模型结果对<img src='${googleLogo}' /><span style='color:#6C38E0'>google 系列产品（shopping/⾕歌seo等）</span>，进⾏了针对性优化，在此场景下使⽤效果更优。`,
      },

      // 表单
      formUrl: {
        cateName: "Women's Down & Parkas  ",
        imgUrl:
          'https://www.dhresource.com/f2/albu/g8/M00/91/E7/rBVaV12JxqaARCPHAAFlDC_PwCM818.jpg',
        oragnalTitle:
          'Big fur winter coat thickened parka women stitching slim long winter coat down cotton ladies down parka down jacket women',
      },
      formUrlListValue: {
        //出参值 新标题 、选定商品词、更换词
        newTitle: '',
        newWord: '',
        productWord: '',
        imgUrl: '',
      },
      action1: `${apiUri}/img/imgUpload`,
      limit: 1,
      isActive: false,
      randomList: [
        {
          title:
            'Big fur winter coat thickened parka women stitching slim long winter coat down cotton ladies down parka down jacket women',
          linkUrl:
            'https://www.dhresource.com/f2/albu/g8/M00/91/E7/rBVaV12JxqaARCPHAAFlDC_PwCM818.jpg',
          typeName: "Women's Down & Parkas",
        },
        {
          title:
            'Sweatshirts Pure Euro-American Simple Personality Trend Sanitary Clothes Jacket Hat Glasses zipper Hoodie mens Hoodies',
          linkUrl:
            'https://www.dhresource.com/webp/m/0x0/f2/albu/g17/M01/F0/03/rBVa4l_Wzh-AIuoPAAMTn3q8vTQ706.jpg',
          typeName: "Men's Hoodies & Sweatshirts",
        },
        {
          title:
            'Stretch Design Denim Jeans Biker Fit For Mens Slim Painted Patch Trim Leg Cowboy Pants Male',
          linkUrl:
            'https://www.dhresource.com/webp/m/0x0/f2/albu/g9/M01/1E/E5/rBVaWF8BtdaANWe0AAWCCcEta3w601.jpg',
          typeName: "Men's Jeans",
        },
        {
          title:
            'Big fur winter coat thickened parka women stitching slim long winter coat down cotton ladies down parka down jacket women',
          linkUrl:
            'https://www.dhresource.com/f2/albu/g8/M00/91/E7/rBVaV12JxqaARCPHAAFlDC_PwCM818.jpg',
          typeName: "Women's Down & Parkas",
        },
        {
          title:
            'Sweatshirts Pure Euro-American Simple Personality Trend Sanitary Clothes Jacket Hat Glasses zipper Hoodie mens Hoodies',
          linkUrl:
            'https://www.dhresource.com/webp/m/0x0/f2/albu/g17/M01/F0/03/rBVa4l_Wzh-AIuoPAAMTn3q8vTQ706.jpg',
          typeName: "Men's Hoodies & Sweatshirts",
        },
      ],
      num: [],
    };
  },
  computed: {
    comma() {
      const arr = Array.from(
        { length: this.num.length },
        (val, i) => i,
      ).reverse();
      const res = arr.filter((item, index) => {
        console.log(!((index + 1) % 3));
        return !((index + 1) % 3);
      });
      console.log(res);
      return res;
    },
  },
  mounted() {
    this.splitNum();
  },
  methods: {
    // 默认图片
    // setDefaultImage(e) {
    //   e.target.src = 'defaultImage';
    // },
    splitNum() {
      this.num = '76880980'.split('');
      console.log(this.num);
      let numArr = [];

      this.num.map((item, index) => {
        numArr[index] = [];
        let i = 0;
        if (item !== ',') {
          // 如果不是第一次循环，要把上一次产生的数组也加进来
          if (index != 0) {
            numArr[index] = numArr[index].concat(numArr[index - 1]);
            // 取数组的最后一个赋值给 i
            i = numArr[index].pop();
          }

          while (i != item) {
            numArr[index].push(i);
            i++;
            // 这里取余是为了在 i 等于 10 的时候重置为 0
            i = i % 10;
          }
        } else {
          numArr[index].push(i);
        }

        //由于判断的是 i 不等于 item, 所以最后我们把item加进来
        numArr[index].push(item);
      });
      console.log(numArr);

      this.numArr = numArr;
    },

    // 处理
    async submitForm() {
      // this.$message({
      //   message: '功能尚未开放',
      //   type: 'info',
      // });
      // return;
      const { cateName, imgUrl, oragnalTitle } = this.formUrl;
      if (cateName === '' || imgUrl === '' || oragnalTitle === '') {
        // return this.$message.error("请输入内容");
        this.$message({
          message: '请输入内容',
          type: 'error',
        });
        return;
      }
      this.isActive = true;
      document.getElementById('msgTip').style.display = 'block';
      const res = await postSubmitForm(this.formUrl);
      if (res.data.state === '0x0000') {
        setTimeout(() => {
          this.isActive = false;
          if (document.getElementById('msgTip')) {
            document.getElementById('msgTip').style.display = 'none';
          }
          let { data } = res.data;
          // 出参 赋值
          this.formUrlListValue.newTitle = data.newTitle;
          this.formUrlListValue.newWord = data.newWord;
          this.formUrlListValue.productWord = data.productWord;
          this.formUrlListValue.imgUrl = data.imgUrl;
        }, 1000);
      } else if (res.data.state === '0x0008') {
        this.isActive = true;
        document.getElementById('msgTip').style.display = 'none';
        document.getElementById('msgTips').style.display = 'block';
        // this.$message.error(res.data.msg);
        this.$message({
          message: res.data.message,
          type: 'error',
        });
        this.$refs.navheader.logOn();
      } else {
        this.isActive = false;
        document.getElementById('msgTip').style.display = 'none';
        this.$message({
          message: res.data.message,
          type: 'error',
        });
        // this.$message.error(res.data.msg);
      }
    },

    // 上传成功回调
    handlePreview(response, file, fileList) {
      console.log(response);
      if (response.state === '0x0000') {
        this.formUrl.imgUrl = response.data;
      } else {
        this.$message.error(response.message);
      }
    },

    // 删除
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.formUrl.imgUrl = '';
    },

    // 随机示例
    randomClicked() {
      var index = Math.floor(Math.random() * 5);
      this.formUrl.oragnalTitle = this.randomList[index].title;
      this.formUrl.imgUrl = this.randomList[index].linkUrl;
      this.formUrl.cateName = this.randomList[index].typeName;
    },
  },
};
</script>

<style lang="less">
a {
  cursor: pointer;
}

.title_class {
  width: 1280px;
  height: 508px;
  border-radius: 16px;
  background: #ffffff;
  margin: 0 auto;
  padding: 24px;
  box-sizing: border-box;
}

.title_clas2 {
  position: relative;
  flex: 1;
  width: 100%;
  height: 100%;
  &:after {
    content: '';
    position: absolute;
    right: -26px;
    top: 0;
    height: 100%;
    width: 2px;
    background: #f0f2f5;
  }
  &:last-child {
    &::after {
      display: none;
    }
  }
}

.title_clas2 p {
  margin: 0;
  font-size: 16px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  color: #000108;
  line-height: 16px;
  margin-bottom: 48px;
  font-weight: 600;
}

.title_clas2 p a {
  font-family: PingFang-SC-Medium, PingFang-SC;
  color: #6c38e0;
  line-height: 16px;
  text-decoration: underline;
}

.title_clas1 {
  height: 100%;
  column-gap: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.titleClass1 {
  background: #ffffff;
  border-radius: 6px 6px 6px 6px;
}

.el-form-item__content:nth-child(2) {
  display: flex;
}

.titleP {
  font-size: 14px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
  margin: 20px 0 0;
}

.formText_analyze {
  /* margin-bottom: 40px; */
}

.txt_class_sev {
  background: #ffffff;
  border-radius: 6px 6px 6px 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
}

.txt_class_sev p {
  margin-bottom: 42px;
}

.txt_class_sev_sp {
  width: 84px;
  display: block;
  float: left;
  font-size: 14px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #100108;
  line-height: 16px;
}
.txt_class_sev_sp1 {
  width: 350px;
  display: block;
  float: left;
  font-size: 14px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #333333;
  line-height: 16px;
}
.img_old {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 10px;
}

.img_old img {
  width: 108px;
  height: 108px;
  /* margin-top: 15px; */
}

.el-button--primary {
  background: #6567e1 linear-gradient(180deg, #361ecd 0%, #777aed 0%);
  border: none;
}

.el-button--primary:hover {
  background: #6567e1 linear-gradient(180deg, #361ecd 0%, #777aed 0%);
}

.frosted_glassing {
  pointer-events: none;
  filter: blur(3px);
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius="3");
}

.msg-tiped-title {
  position: absolute;
  /* left: 37%;
  top: 50%; */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 0 32px;
  width: fit-content;
  height: 36px;
  line-height: 36px;
  color: #666;
  background: #fff;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.msg-tipsed {
  position: absolute;
  /* left: 37%;
  top: 50%; */
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(20%, -500%);
  padding: 0 32px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 36px;
  line-height: 36px;
  color: #666;
  background: #fff;
  -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
@media screen and (max-width: 990px) {
  .title_class {
    width: 96%;
    height: auto;
    padding: 10px;
  }
  .title_clas1 {
    flex-direction: column;
    width: auto;
  }
  .titleClass1 {
    width: auto;
    margin-right: 0px;
  }
  .title_clas2 {
    flex: 1;
    width: 100%;
    height: 100%;
  }
  .txt_class_sev {
    width: 100%;
  }
  .titleP {
    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #999999;
    line-height: 30px;
    margin: 20px 0 0;
  }
  .title_clas2 p {
    margin: 20px 0 0 0;
    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    color: #333333;
    line-height: 16px;
    margin-bottom: 19px;
  }
  .img_old img {
    width: 80px;
    height: 80px;
    /* margin-top: 15px; */
  }
  .txt_class_sev_sp {
    margin-left: 15px;
    text-align: right;
    width: 120px;
    display: block;
    float: left;
    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #333333;
    line-height: 16px;
  }
  .title_main {
    width: 93%;
    margin: auto;
  }
  .msg-tiped-title {
    position: absolute;
    /* left: 37%;
    top: 50%; */
    -webkit-transform: translate(100%, -500%);
    -moz-transform: translate(100%, -500%);
    -ms-transform: translate(100%, -500%);
    transform: translate(100%, -500%);
    padding: 0 32px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 36px;
    line-height: 36px;
    color: #666;
    background: #fff;
    -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }
}
</style>
<style lang="less" scoped>
// banner
.header-custom {
  width: 100%;
  margin: auto;
  height: 100%;
  position: absolute;
  display: flex;
  left: 50%;
  transform: translate(-50%);
  align-items: flex-end;
  padding-bottom: 2.8%;
  box-sizing: border-box;
  justify-content: center;
  img {
    position: absolute;
  }
  @time: 0.7s;
  .banner1 {
    width: 8.33%;
    bottom: 34%;
    left: 10%;
    margin-right: 1%;
  }
  .banner2 {
    width: 5.7%;
    bottom: 13%;
    left: 38%;
    margin-right: 1%;
  }
  .banner3 {
    width: 8.7%;
    bottom: 12%;
    right: 27%;
  }
  .banner4 {
    width: 12%;
    bottom: 19%;
    right: 7.5%;
    margin-right: 1%;
  }
}
</style>
<style lang="less" scoped>
.core-function_main,
.application-scene_main,
.different-advantage_main {
  margin-top: 80px;
}
/* 核心 */
.core-function {
  position: relative;
  padding: 60px 0 140px;
  .core-function_main {
    margin-top: 80px;
  }
  /deep/ .common-two-column_img {
    width: 32%;
    margin-top: -25px;
    margin-right: 109px;
    z-index: 1;
  }
  /deep/ .common-two-column {
    column-gap: 133px;
  }
}

/* 差异优势 */
.different-advantage-wrap {
  background: linear-gradient(140deg, #503ad9 20%, #722ab8 145%);
  .different-advantage {
    padding-top: 80px;
    padding-bottom: 34px;
  }

  .different-advantage_main_top {
    margin-top: 90px;
    /deep/ &.common-two-column {
      column-gap: 25px;
    }
    /deep/ .common-two-column_img {
      width: 49%;
      margin-right: 0;
      margin-top: -22px;
    }
    /deep/ .section-article {
      background-image: url(../../assets/img/title/diff-logo.png);
      background-repeat: no-repeat;
      background-size: 90% auto;
      background-position: left bottom;
      padding: 10px 0 39px;
    }
  }
  .different-advantage_main_bottom {
    .diff-bar {
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
    }
    /deep/ &.common-two-column {
      column-gap: 24px;
    }
    /deep/ .common-two-column_img {
      width: 48.7%;
      margin-right: 0;
      padding-top: 10px;
      // margin-top: -22px;
    }
    /deep/ .common-two-column_text {
      padding-top: 14%;
      z-index: 2;
    }
  }
  .diff-imgs {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  .diff-list {
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    width: 60%;
    img {
      width: 32%;
    }
  }
  .diff-person {
    width: 31.6%;
    position: relative;
  }
  .mod_txt {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    float: left;
    overflow: hidden;
    width: 22px;
    height: 30px;
    // text-transform: uppercase;
    font-size: 30px;
    color: #333;
    font-family: Inter;
  }

  .mod_txt:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    word-break: break-all;
    // background: #ffffff;
    font-size: 30px;
    color: #333;
  }
  .diff-top {
    position: relative;

    > p {
      position: absolute;
      left: 31%;
      top: 34%;
      font-size: 20px;
      line-height: normal;
      color: #98989e;
    }
  }

  .diff-num {
    color: #000108;
    font-size: 36px;
    font-weight: 600;
    line-height: 60px;
    display: flex;
    position: absolute;
    left: 31%;
    top: 48%;
    // transform: translate(-48%, -31%);
    // transform: translate(-59%, -10%);
    font-family: system-ui;

    > div {
      height: 60px;
      overflow: hidden;
      display: flex;

      > div {
        display: flex;
        flex-direction: column-reverse;
        animation: numIncrease 4s linear 3s;
        animation-fill-mode: forwards;
        transform: translateY(-100%);
        font-family: Inter;

        > div {
          height: 60px;
          text-align: right;
        }
      }
    }

    /*随便写的数，作用就是让这一列数字向上升，形成滚动效果*/

    @keyframes numIncrease {
      0% {
        transform: translateY(-100%);
      }

      100% {
        transform: translateY(0%);
      }
    }
  }
}

/* 应用场景 */
.application-scene-wrap {
  width: 100%;
  background-image: url('../../assets/img/title/app-bg.png');
  background-repeat: no-repeat;
  background-size: contain;
}
.application-scene {
  padding-top: 80px;
  padding-bottom: 126px;
  .application-bag {
    width: 21.25%;
    margin: 70px 0 0 72px;
    img {
      width: 100%;
    }
  }

  /deep/ .common-two-column {
    column-gap: 24px;
  }
  /deep/ .common-two-column_img {
    width: 49.1%;
    margin-right: 0px;
    margin-top: -330px;
    overflow: inherit;
  }
  .app-imgs {
    width: 100%;
    position: relative;
    height: 100%;
    img {
      // position: absolute;
    }
  }
  .app-phone {
    width: 33.3%;
  }
  .app-mac {
    width: 79.1%;
    // margin-left: 15%;
    // margin-top: -50%;
    position: absolute;
    bottom: -93%;
    right: 0;
    z-index: 11;
  }
}
@media screen and(max-width: 1280px) {
  .title_class {
    width: 100%;
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 990px) {
  .different-advantage-wrap {
    .diff-num {
      font-size: 36px;
    }
  }
  .application-scene {
    .application-bag {
      display: none;
    }

    .app-mac {
      position: relative;
      margin-top: -50%;
      margin-left: 80px;
    }
  }
}
// 能力展示
.similar-main-wrap {
  padding: 80px 0;
  width: 100%;
  background: #f0f2f5;
  /deep/ .common-text {
    img {
      vertical-align: middle;
      display: inline-block;
      margin: -2px 3px 0;
      width: 24px;
    }
  }
  .operationBackgroundColor {
    height: calc(100% - 62px);
  }
  .similarityGraph {
    width: 32.8%;
    background: #d8d8d8;
    border-radius: 8px;
  }
  .operationCenter {
    width: 71.25%;
    height: auto;
    margin-left: 16px;
    img {
      max-width: 100%;
      max-height: 100%;
      width: fit-content;
    }
  }
  .successImgProduct {
    width: 100%;
  }
  .operationYellowRight {
    flex: 1;
    flex-shrink: 0;
    height: 100%;
    margin: 7px 0 0 24px;
  }
  .recognitionResult {
    margin: 0;
    font-size: 18px;
    color: #000108;
    span {
      color: #ff8645;
    }
  }
  .detectionButton {
    background: rgba(108, 56, 224, 0.1);
    color: rgba(108, 56, 224, 1);
  }
  .confidenceLevel {
    margin: 24px 0 0;
    font-size: 14px;
  }
  .similarityGraphMain {
    height: 100%;
    justify-content: space-between;
    row-gap: 0.8%;
    column-gap: 0.8%;
  }
  .similarityGraphImg img {
    border-radius: 8px;
    overflow: hidden;
  }
  /deep/ .el-form-item {
    margin-bottom: 56px;
    &:last-child {
      margin-top: 64px;
    }
  }
  /deep/ .el-input--suffix .el-input__inner {
    padding-right: 84px;
  }
  /deep/ .el-form-item__label,
  /deep/.el-input__inner {
    color: #000108;
    text-align: left;
  }
  .el-button--primary {
    background: #6c38e0;
  }
  .input-suffix {
    .el-button--small {
      padding: 13px 14px;
      font-size: 14px;
      border-radius: 0 4px 4px 0;
    }
    .el-button--primary {
      background: #6c38e0;
    }
    /deep/.el-input__suffix {
      right: 0;
    }
  }
  /deep/.el-button.chuBtn {
    padding: 13px 53px;
    // margin-left: 0px;
    float: right;
    background: #6c38e0;
    font-size: 16px;
  }
}
.similar-main-cont {
  // display: flex;
  width: 100%;
  height: 770px;
  margin: auto;
  background-color: #fff;
  padding: 16px;
  border-radius: 16px;
  box-sizing: border-box;
}
@media screen and (min-width: 1440px) {
  .header-custom {
    width: 1440px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
